.App {
  text-align: center;
}

body {
  background: rgb(41, 43, 44);
}

@media only screen and (max-width: 991px) {
  #homeCar{
    max-width: 75%;
  }
}

@media only screen and (min-width: 992px) {
  #homeCar{
    max-width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  #commIMG{
    max-width: 90%;
  }
}

@media only screen and (min-width: 992px) {
  #commIMG{
    max-width: 80%;
  }
}

#charityVideo{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
#charityVideo iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}